// Global styling for this template
body {
  overflow-x: hidden;
  @include body-font;
}

p {
  line-height: 1.75;
}

a {
  color: $primary;
  &:hover {
    color: darken($primary, 10%);
  }
}

.text-primary {
  color: $primary !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  @include heading-font;
}

section {
  padding: 100px 0;
  h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px;
  }
  h3.section-subheading {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
    @include serif-font;
  }
}
@media(min-width:768px) {
  section {
    padding: 150px 0;
  }
}

// Button Styles
.btn {
  @include heading-font;
  font-weight: 700;
}

.btn-xl {
  font-size: 18px;
  padding: 20px 40px;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:active,
  &:focus,
  &:hover {
    background-color: darken($primary, 7.5%) !important;
    border-color: darken($primary, 7.5%) !important;
    color: white;
  }
  &:active,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 209, 55,.5) !important;
  }
}

// Highlight color customization
::-moz-selection {
  background: $primary;
  text-shadow: none;
}

::selection {
  background: $primary;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}
